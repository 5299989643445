import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'

import { normalizeBlockContentNodes } from '@blocks-helpers'
import Blog3 from '@solid-ui-blocks/Blog/Block03'
import httpService from '../../service/httpService';
import loadsGif from '../assets/loads.gif'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'

const BicakIzi = props => {


  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const [benzerModelList, setBenzerList] = useState([]);
  const [dtID, setIDBilgi] = useState("0");
  const [ldg, setLdg] = useState(false);


  useState(() => {
    if (typeof window !== 'undefined') {


      // if (props.location.state != null) {
      // localStorage.setItem("pM", props.location.state?.dt);
      // }

      var ddd = localStorage.getItem("pM");
      if (!ddd || ddd == 'undefined') {
        localStorage.setItem("pM", "53");
        ddd = "53"
      }


      setIDBilgi(ddd);

      benzerList(ddd);
    }
  }, []);


  function benzerList(mdlId) {
    setLdg(false);
    httpService.post("UnLocked/BenzerModelList", { params: mdlId }).then((res) => {
      if (res.DogruMu) {

        setBenzerList(res)
        setLdg(true);

      } else {

      }
    }).catch((err) => {

    });
  }
  
  if (dtID == "0") return (<></>);
  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Online Bıçak İzi Vektörel İndirme - Custom dieline' />
      <Divider space='5' />
      {ldg == false ? <div className='dv-l'>
        <Divider space="3" />
        <img src={loadsGif} alt="online box die cutting loading" style={{ width: "200px", objectFit: "contain" }} />
        <Divider space="5" />
      </div> :
        <Blog3 id={`blgbicak`} content={{ Id: dtID }} benzerModelList={{ benzerModelList: benzerModelList }} />
      }
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageBicakIziBlockContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`


export default BicakIzi
